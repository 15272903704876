@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html, body {
        scroll-behavior: "smooth";
        background-color: theme('colors.background');
        color: theme('colors.white');
        overflow-wrap: 'break-word';
        font-family: 'Oswald'
    }

    section#legal-disclosure h1, section#privacy-policy h1 {
        @apply text-3xl;
        @apply mt-10;
    }

    section#legal-disclosure h2, section#privacy-policy h2 {
        @apply text-2xl;
        @apply mt-6;
    }

    section#legal-disclosure h3, section#privacy-policy h3 {
        @apply text-xl;
        @apply mt-4;
    }

    .navbar-link {
        @apply py-2 px-4 outline-none transition-none active:rounded-none [&.active]:border-b-2 [&.active]:border-navbarActiveItem hover:cursor-pointer hover:no-underline hover:rounded-md hover:bg-navbarActiveItem;
    }

    .navbar-btn {
        @apply p-2 hover:cursor-pointer hover:no-underline hover:bg-navbarActiveItem hover:rounded-md;
    }
}